@import '../../../styles/_responsive-bg.scss';
@import '../../../styles/_utils.scss';
@import '../../../styles/components.scss';

.map {
  composes: container;
  display: flex;
  padding: 60px 15px;
  text-align: center;

  @include tablet-down {
    flex-direction: column;
    padding: 30px 15px;
  }

  > div {
    flex: 1;
  }
}

.mapWrapper {
  
}

.desc {
  display: flex;
  flex-wrap: wrap;
  line-height: 1.6;

  @include mobile {
    flex-direction: column;
  }

  @include desktop {
    flex-direction: column;
  }

  > div {
    flex: 1;
  }
}

.title {
  font-size: 18px;
  margin: 0px 0 10px;
  color: #4A4A4A;
}

.text {
  font-size: 16px;
  margin: 0px 0 20px;
}
