@import '../../styles/_utils.scss';

.container {
  composes: container;
  overflow: hidden;
}

.textCenter {
  // font-family: 'Rubik One', sans-serif;
  font-size: 18px;
  text-transform: uppercase;
  background: linear-gradient(45deg, #180101 33%, #A10101 66%, #e45959);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #A10101;
  display: table;
  margin: 20px auto;
  padding: 60px 20px;
  text-align: center;

  @include tablet {
    font-size: 50px;
  }
}
  

.textCenter2 {
  // font-family: 'Rubik One', sans-serif;
  font-size: 30px;
  text-transform: uppercase;
  // background: linear-gradient(45deg, #180101 33%, #A10101 66%, #e45959);
  // -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;
  color: #000;
  display: table;
  margin: 20px auto;
  padding: 60px 20px;
  text-align: center;
  > *:last-child {
    color: #A10101;
  }
}

.advantages {
  padding-bottom: 30px;
  h3 {
    text-align: center;
    padding: 30px 0;
    font-size: 30px;
  }
  > div {
    max-width: 600px;
    // padding: 0 15px;
    margin: 15px auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    img {
      width: 100px;
      margin-right: 20px;
      height: auto;
    }
    > div {
      line-height: 1.8;
    }
  }
}

.ctaBtns {
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 30px 0;
}

.mainWrapper {
  margin: 20px 0 0;
}

.team {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.teamItem {
  padding: 10px;
  width: 100%;
  max-width: 320px;
  min-width: 240px;
  box-sizing: border-box;
  position: relative;

  @media(min-width: 1280px) {
    width: 25%;
  }

  @media(min-width: 1280px) {
    width: 25%;
  }


  .teamItemText {
    position: absolute;
    bottom: 10px;
    left: 10px;
    width: calc(100% - 20px);
    padding: 10px;
    box-sizing: border-box;
    background: rgba(0,0,0,0.6);
    color: #fff;
    transform: scale(1);
    transition: 0.15s ease-in;
    font-size: 14px;
    line-height: 1.4;
    display: flex;
    flex-direction: column;
    span {
      color: rgba(255,255,255,0.7);
    }
  }

  img {
    
    width: 100%;
    // max-height: 200px;
    height: auto;
    -webkit-transition: 0.3s ease;
    transition: 0.3s ease;


    @include desktop {
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
    }
  }


  &:hover {
    .teamItemText {
      transform: scale(1);
    }
    img {
      -webkit-filter: none;
      filter: none;
    }
  }
}