@import '../../styles/_utils.scss';
@import '../../styles/_colors.scss';

.navSeparate {
  color: #fff;
  font-weight: 400;
  font-size: 10px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  height: 80px;


  @include desktop {
    justify-content: flex-start;
  }
}

.mobileNavIcon {
  height: 40px;
  width: 40px;
  background: url(./imgs/menu.svg) no-repeat center center;
  background-size: contain;
  border: 0;

  display: block;

  @include desktop {
    display: none;
  }
}

.navHead {
  justify-content: center;

  display: none;

  @include desktop {
    display: flex;

    .navLink {
      
    white-space: nowrap;
    }

  }
}

.col {
  min-width: 20%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;

    &:nth-child(1) {
        order: 2;
        justify-content: flex-end;
        padding-right: 20px;
    }
    &:nth-child(2) {
        order: 1;
        justify-content: center;
        padding-left: 0;
    }
    &:nth-child(3) {
        display: none;
    }
    &:nth-child(4) {
        padding-left: 20px;
        justify-content: flex-start;
    }

  
  @include desktop {
    min-width: 0;
    &:nth-child(1) {
        order: 1;
        margin-right: auto;
        padding-left: 30px;
    }
    &:nth-child(2) {
        order: 2;
        padding: 0 15px;
    }
    &:nth-child(3) {
        order: 3;
        padding: 0 30px 0 15px;
    }
    &:nth-child(4) {
        order: 3;
        padding: 0 30px 0 15px;
    }

  }
}

.geocol {
  display: none;
  composes: col;

  @include tablet {
    display: none;
  }

  @include desktop-md {
    background: red!important;
    display: flex;
  }
}

.mobileNav {
  display: none;
  flex-direction: column;
  background: rgb(238, 238, 238);
  position: absolute;
  width: 100%;
  z-index: 999;
  top: 100%;
  left: 0;
  padding: 20px;
  animation: fadeIn 0.3s ease-in 1;
  box-sizing: border-box;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @include desktop {
    display: none;
  }

  .navLink {
    color: #2e2e2e;
    margin: 15px 0;
    font-size: 16px;
    text-align: right;
    border: 0;

    &:hover {
      border: 0;
    }
  }

.geoLinkMobile { 
  font-size: 18x;
}

}
.navTitle {
  text-align: right;
  margin-top: 30px;
  color: #a10101;
  font-weight: 400;
}
.geoLinkMobile {
  composes: navLink;
  font-size: 22px;
  text-align: center;
}
.mobileNavIsOpen {
  composes: mobileNav;
  display: flex;
}

.headerFixed {
  composes: header;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0,0,0,1);
  padding-top: 0;
  height: 60px;

  animation: slide ease-in 0.3s;

  @keyframes slide {
    0% {
      transform: translateY(-30px);
    }
    30% {
      transform: translateY(0px);
    }
  }
}

.navLink {
  color: #fff;
  font-size: 16px;

  border-bottom: 1px dotted transparent;

  @include desktop {
    margin: 0 10px;
  }
  &:hover {
    text-decoration: none;
    border-bottom-color: #fff;
  }
}

.navLinkActive {
  composes: navLink;
  color: $red;
}

.emptyNav {
  height: 60px;
}

.contactLink {
  font-size: 16px;
  color: #fff;

  > small {
    font-size: 12px;
    display: none;
  }

  @include tablet {
    
  }

  @include desktop {
    font-size: 22px;

    > small {
      display: inline;
    }
  }
}

.geoLink {
  composes: contactLink;
  display: none;
  border-bottom: 1px dotted transparent;

  &:hover {
    text-decoration: none;
    border-bottom: 1px dotted #fff;
  }

  @include desktop {
    display: block;

  }
}


.telLink {
  composes: contactLink;

  border-bottom: 1px dotted transparent;
  &:hover {
    text-decoration: none;
    border-bottom: 1px dotted #fff;
  }
}
.logoMobile {
  img {
    width: 50px;
  }
  @include desktop {
    display: none;

  }
}
