@import '../../styles/_utils.scss';

.container {
  composes: container;
  overflow: hidden;
}

.advantages {
  padding-bottom: 30px;
  margin-top: 60px;
  h3 {
    text-align: center;
    padding: 30px 0;
    font-size: 30px;
  }
  > div {
    max-width: 600px;
    // padding: 0 15px;
    margin: 15px auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    img {
      width: 50px;
      margin-right: 20px;
      height: auto;
    }
    > div {
      line-height: 1.8;
    }
  }
}

.prices {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin: 60px 0 40px;

  @include tablet {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }

}
.pricesInfo {
  margin-top: 15px;
  margin-bottom: 60px;
}
.pricesItem {
  border-radius: 10px;
  background: #283048;
  color: #fff;
  padding: 15px;

  &:nth-of-type(2n-1) {
    background: #1D976C;
  }

  > span {
    font-size: 18px;
  }

  > div {
    margin-top: 20px;
    text-align: right;
  }
}

.portfolio {
}

.portfolioItem {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;
  padding: 30px 15px;
  line-height: 1.6;

  @include tablet {
    grid-template-columns: 1fr 1fr;
  }
  video {
    height: 300px;
    width: 100%;
    margin: 20px 0px;
  }
}
.portfolioItemCenter {
  composes: portfolioItem;
  grid-template-columns: 1fr;
  text-align: center;
}
.portfolioPhotos {
  display: grid;
  grid-template-columns: 1fr;
  padding: 18px 0;
grid-gap: 30px;
  @include tablet {
    grid-template-columns: 1fr 1fr;
  }
  >div {
    > img {
      max-width: 300px;
    }
    > span {
      display: block;
      text-align: center;
      color: #4A4A4A;
      font-size: 12px;
    }
  }
}
.portfolioPhotos4 {
  composes: portfolioPhotos;
  @include tablet {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.portfolioDesc {
  padding: 15px;
}