@import '../../styles/_utils.scss';
@import '../../styles/components.scss';

.wrapper {
  background-color: #4A4A4A;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
  padding: 40px 0 20px;
}

.container {
  display: flex;
  justify-content: space-between;

  > div {
    flex: 1;
  }

  @include tablet-down {
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
}

.colLogo {
  display: flex;
  justify-content: flex-end;
}

.colSocLiks {
  color: #fff;
  text-align: center;

  @include tablet-down {
    margin: 30px 0 40px;
  }
  h4 {
    margin: 0;
    line-height: 1.6;
    font-size: 20px;
    font-weight: 400;
  }
}
.socLinks {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 20px;

  > a {
    background-color: #fff;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    margin: 0 5px;

    > svg {
      width: 25px;
      path {
        fill: #000;
      }
    }

    &:hover {
      background-color: #A10101;
      > svg {
        path {
          fill: #fff;
        }
      }
    }


  }
}


.contactLink {
  line-height: 1.6;
  font-size: 14px;
  color: #fff;

  > small {
    font-size: 12px;
    display: none;
  }

  @include tablet {
    
  }

  @include desktop {

    > small {
      display: inline;
    }
  }
}

.geoLink {
  composes: contactLink;
  display: flex;
  // display: none;
  color: #C1C1C1;
  border-bottom: 1px dotted transparent;

  &:hover {
    text-decoration: none;
    border-bottom: 1px dotted #fff;
  }

}


.telLink {
  composes: contactLink;
  font-size: 1.7rem;

  &:hover {
    text-decoration: none;
    border-bottom: 1px dotted #fff;
  }
}


.ctaBtns {
  display: flex;
  margin-top: 34px;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;


  @include tablet {
    flex-direction: row;
  }
}

.btnLeft {
  composes: btn;
  background-image: url(../Home/Head/imgs/whatsapp.svg);
  background-repeat: no-repeat;
  background-position: 20px center;
  background-size: 27px;
  padding-left: 62px;

  &:hover {
    background-color: #25D366;
  }
}

.btnRight {
  composes: btn;
  background-image: none;
  background-color: #A10101;
  justify-content: flex-start;

  span {
    display: block;

    &:nth-child(2) {
      margin-left: 21px;
    }
  }
}

.copyright {
  composes: container;
  margin-top: 50px;
  font-size: 14px;
  color: #C1C1C1;
  letter-spacing: 0;
  text-align: center;
  align-items: center;
  line-height: 1.6;

  @include tablet-down {
    a {
    margin-top: 30px;
  }
  }
  
}

.logoSV {
  background: url('./logo-sv.svg') no-repeat 0 0;
  background-size: contain;
  width: 180px;
  height: 30px;
  opacity: 0.6;
}

