@import '../../styles/_utils.scss';
@import '../../styles/components.scss';

.ctaBtns {
  display: flex;
  margin-top: 34px;
  font-size: 16px;
  flex-direction: column;

  @include tablet {
    flex-direction: row;
  }
}

.btnLeft {
  composes: btn;
  background-image: url(./whatsapp.svg);
  background-repeat: no-repeat;
  background-position: 20px center;
  background-size: 27px;
  padding-left: 62px;

  &:hover {
    background-color: #25D366;
  }
}

.btnRight {
  composes: btn;
  background-image: none;
  background-color: #A10101;
  justify-content: flex-start;

  span {
    display: block;

    &:nth-child(2) {
      margin-left: 21px;
    }
  }
}

