@import '../../../styles/_responsive-bg.scss';
@import '../../../styles/_utils.scss';
@import '../../../styles/components.scss';

.header {
  position: relative;
  min-height: 620px;
  background-attachment: fixed;

  @include tablet {
    min-height: 700px;
  }
  
  // padding-top: 93px;

  &:after {
    content: '';
    display: block;
    position: absolute;
    z-index: 0;
    background: rgba(0,0,0,0.4);
    width: 100%;
    height: 100%;
  }
}

.headerWrapper {
  position: absolute;
  z-index: 1;
  display: flex;
  top: 0;
  left: 0;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
}


:global(.no-webp) .header {
  @include responsive-background-image(
    cover,
    "./imgs/header-bg",
    "jpg"
  );
}
:global(.has-webp) .header {
  @include responsive-background-image(
    cover,
    "./imgs/header-bg",
    "webp"
  );
}

.logo {

  @include mobile {
    margin-top: 60px;
  }
}

.title {
  font-size: 24px;
  font-weight: 400;
  text-align: center;
  color: #fff;
  margin-top: 40px;

  margin-bottom: 40px;
    
  padding: 0 30px;

  br {
    display: none;
  }

  @include tablet {
    font-size: 48px;
    text-align: center;
    margin-top: 70px;
    padding: 0;
    margin-bottom: 0.67em;
    

    br {
      display: inline;
    }
  }
}

.playBtn {
  border: 0;
  opacity: 0.8;
  color: #fff;
  padding-left: 62px;
  display: flex;
  align-items: center;
  background: url(./imgs/play.svg) no-repeat 0 center;
  background-size: 47px;
  height: 47px;
  cursor: pointer;
  transition: 0.2s ease-in;

  &:hover {
    opacity: 1;
  }
}

.ctaBtns {
  display: flex;
  margin-top: 34px;
  flex-direction: column;

  @include tablet {
    flex-direction: row;
  }
}

.btnLeft {
  composes: btn;
  background-image: url(./imgs/whatsapp.svg);
  background-repeat: no-repeat;
  background-position: 20px center;
  background-size: 27px;
  padding-left: 62px;

  &:hover {
    background-color: #25D366;
  }
}

.btnRight {
  composes: btn;
  background-image: none;
  background-color: #A10101;
  justify-content: flex-start;

  span {
    display: block;

    &:nth-child(2) {
      margin-left: 21px;
    }
  }
}



.modal{
  position: fixed;
  bottom: -40px;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 999;
  

}

.modalFullScreen {
  composes: modal;
  background: rgba(0,0,0,1);
  z-index: -1;
  opacity: 0;
  transition: .25s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalCloseBtn {
  display: block;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 30px;
  height: 30px;
  opacity: 0.6;
  transition: .25s ease;
  cursor: pointer;
  border-radius: 10px;
  background: #fff;
  z-index: 11;

  &:hover {
    opacity: 0.8;
  }

  svg {
    width: 100%;
    height: 100%;
  }
}


.modalFullScreenIsOpen {
  composes: modalFullScreen;
  z-index: 999;
  opacity: 1;
  bottom: 0;
}

.mouse {
  border: 0;
  width: 30px;
  height: 48px;
  cursor: pointer;
  position: absolute;
  bottom: 20px;
  left: 50%;
  margin-left: -15px;
  background: transparent;
  border: 0;
  animation: pounse ease-in 2s infinite;

  display: none;

  @include desktop {
    display: block;
  }

  svg {
    width: 100%;
    fill:none;stroke:#fff;stroke-width:20px;
  }

  &:focus {
    outline: none;
  }

  @keyframes pounse {
    0% {
      transform: translateY(0px);
    }
    30% {
      transform: translateY(5px);
    }
  }
}


.mouseBord {
  fill:none;stroke:#fff;stroke-width:20px;
}
.wheel {
  animation: scroll ease 2s infinite;
  fill: none;
  stroke: #fff;
  stroke-width: 20px;

  @keyframes scroll {
    0% {
      transform: translateY(0);
    }
    30% {
      transform: translateY(60px);
    }
  }
}