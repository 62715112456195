@import '../../../styles/_responsive-bg.scss';
@import '../../../styles/_utils.scss';
@import '../../../styles/components.scss';

.btn, .btnSecondary {
  composes: btn;
  background-image: none;
  background-color: #A10101;
  justify-content: center;
  width: 100%;
    margin: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

  span {
    display: block;

    &:nth-child(2) {
      margin-left: 21px;
    }
  }
}

.btnSecondary {
  background: #fff;
  color: #A10101;

  &:hover {
    background: rgba(29, 151, 108, .6);  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, rgba(147, 249, 185, .6), rgba(29, 151, 108, .6));  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, rgba(147, 249, 185, .6), rgba(29, 150, 107, .6)); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  }
}

.fullContainer {
  position: relative;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  width: 100%;

  @include tablet-down {
    flex-direction: column;
    align-items: center;
  }
}

  // @include jpg-or-webp-background-image(
  //   cover,
  //   "./imgs/bg",
  //   ".fullContainer"
  // );


.head {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9;

  font-size: 16px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.66);
  // background-color: rgba(161, 1, 1, 0.7);
  // background-color: #000;

  @include tablet-down {
    position: relative;
  }
}

.headWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  max-width: 1280px;
  padding: 0 30px;
  margin: 0 auto;
  box-sizing: border-box;

  @include tablet-down {
    height: 60px;
  }
}

.headTitle {
  font-weight: 400;
  font-size: 36px;
  margin: 0;

  @include tablet-down {
    font-size: 14px;
  }
}

.headSubTitle {
  font-weight: 400;
  line-height: 30px;
  margin: 0;
  display: flex;
  align-items: center;

  @include tablet-down {
    display: none;
  }

  strong {
    font-size: 22px;
    font-weight: 400;
    padding-right: 20px;
  }
}

.body {
  background: #283048;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #859398, #283048);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #859398, #283048); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
width: 100%;
padding-top: 0px;
padding-bottom: 30px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;

  @include desktop {
    padding-top: 180px;
    padding-bottom: 50px;
  }
}

.tarifs {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;


  @include desktop {
    flex-direction: row;
  justify-content: space-around;
  padding-left: 0;
  padding-right: 0;
  }
}


.tarifsItem {
  background: #fff;
  // margin: 0 10px;
  border-radius: 8px;
  overflow: hidden;
  // transition: 0.25s linear;
  //cursor: pointer;
  margin-top: 50px;
  width: 100%;

  @include desktop {
    margin-top: 0px;
    flex-basis: 30%;
  }

  &:hover {

  }


  &:nth-child(2) {
    @include desktop {
      transform: scale(1.1);
    }

    .tarifsItemHead {
      border-bottom: 0;
      // transition: 0.25s linear;
      background: rgba(255, 128, 8, 0.6);  /* fallback for old browsers */
      background: -webkit-linear-gradient(to right, rgba(255, 200, 55, 0.6), rgba(255, 128, 8, 0.6));  /* Chrome 10-25, Safari 5.1-6 */
      background: linear-gradient(to right, rgba(255, 200, 55, 0.6), rgba(255, 128, 8, 0.6)); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    }
  }
}

.tarifsItemHead {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 10px 15px;
  height: 110px;
  border-bottom: 1px solid rgba(0,0,0,0.1);

  > p {
    color: #A10101;
    font-size: 18px;
    border-bottom: 1px dashed #A10101;
    width: max-content;
  }

  > span {
    font-size: 14px;
  }
}

.tarifsItemBody {
  padding: 20px 15px;
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      margin: 10px 0;
    }
  }

  .price {
    font-size: 22px;
    text-align: center;
    margin: 30px 0 20px;
    color: #1D976C;

    > span {
      font-size: 22px;
      color: #1D976C;
    }

    > del {
      font-size: 16px;
      color: rgba(0,0,0,0.8);
    }
  }

  .doubleprice {
    text-align: center;
    > p {
    font-size: 18px;
      margin: 0;
    }

    span {
      color: rgba(0,0,0,0.6);
      font-size: 14px;
    }
  }
}

.tarifsInfo {
  margin-top: 50px;
  color: #c5c5c5;
  font-size: 16px;
}

.table {
  margin: 0 40px;
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  max-width: 100%;
  overflow-x: auto;
  table {
    width: 100%;
    border-collapse: collapse;
  }
  table, th, td {
    border-top: 1px solid rgba(0,0,0,0.1);
  }
  th, td {
    padding: 20px;
    text-align: left;
    vertical-align: top;
    font-weight: lighter;
  }
  th {
    color: #A10101;
    font-weight: lighter;
    white-space: nowrap;
  }
  td ul {
    list-style: circle;
    padding-left: 0;
    margin: 0;
  }
  td ul li {
    padding-bottom: 10px;
    margin-bottom: 10px;
  }

  .advanced {
    color: #1D976C;
  }
}