@import '../../styles/_colors.scss';
@import '../../styles/_utils.scss';

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.icon {
  background-image: url("./imgs/logo.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  width: 130px;
  height: 108px;

  @include tablet {
    width: 250px;
    height: 180px;
  }
}

.texts {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: rgba(255, 255, 255, 0.6);


  > h2 {
    font-size: 3em;
    margin: 0;
    font-weight: normal;

    @include tablet {
      font-size: 4.3em;
    }
  }

  > h1 {
    border-left: 3px #A10101 solid;
    padding-left: 20px;
    line-height: 1.4;
    font-weight: normal;
    font-size: 14px;
    @include tablet {
      font-size: 21px;
    }
  }
}


.wrapperSmall {
display: flex;
align-items: center;

.icon {
  background-image: url("./imgs/logoWhite.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  width: 130px;
  height: 100px;
}

.texts {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: rgba(255, 255, 255, 1);


  > h2 {
    font-size: 2.7em;
    margin: 0;
    font-weight: normal;
  }

  > h1 {
    border-left: 3px #A10101 solid;
    padding-left: 10px;
    line-height: 1.4;
    font-weight: normal;
    font-size: 13px;
  }
}
}