$tablet-width: 768px;
$desktop-width: 1280px;
$desktop-md-width: 1440px;

@mixin mobile {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin tablet-down {
  @media (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: #{$tablet-width}) {
    @content;
  }
}
@mixin desktop-md {
  @media (min-width: #{$desktop-md-width}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
  box-sizing: border-box;

  @include tablet {
    width: 768px;
  }

  @include desktop {
    width: 1280px;
  }
}

.row {
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
}

