@import url('https://fonts.googleapis.com/css?family=Russo+One');
@import "normalize.css";

@import "utils";
@import "colors";

body {
  margin: 0;
  padding: 0;
  font-family: 'Russo One', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
}

a {
  color: $red;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}


h1,h2,h3,h4,h5,h6 {
  font-weight: 400;
  margin: 0;
}

