@import '../../../styles/_responsive-bg.scss';
@import '../../../styles/_utils.scss';

.fullContainer {
  position: relative;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  width: 100%;

  @include tablet-down {
    flex-direction: column;
    align-items: center;
  }



  background-image: url("./imgs/bgService.webp");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.estimateWrapper {
  position: relative;
  width: 100%;
  max-width: 1200px;
  max-height: 800px;
  min-height: 213px;
  min-width: 585px;
  overflow: hidden;

  svg {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 100%;

    path {
      fill: transparent;
      cursor: pointer;
      transition: 0.3s ease-in;
      &:hover {
        fill: rgba(0, 0, 0, 0.3);
      }

      &:global(.is-active) {
        fill: rgba(161, 1, 1, 0.6);
      }
    }
  }

  img {
    height: 100%;
    width: 100%;
  }
}


.head {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9;

  font-size: 16px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.66);

  @include tablet-down {
    position: relative;
  }
}

.headWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  max-width: 1280px;
  padding: 0 30px;
  margin: 0 auto;
  box-sizing: border-box;

  @include tablet-down {
    height: 60px;
  }
}

.headTitle {
  font-weight: 400;
  font-size: 36px;
  margin: 0;

  @include tablet-down {
    font-size: 14px;
  }
}

.headSubTitle {
  font-weight: 400;
  line-height: 30px;
  margin: 0;
  display: flex;
  align-items: center;

  @include tablet-down {
    display: none;
  }

  strong {
    font-size: 22px;
    font-weight: 400;
    padding-right: 20px;
  }
}

.time {
  font-weight: 400;
  padding-left: 50px; 
  line-height: 30px;
  background: url(./imgs/clock.svg) no-repeat center left;
  background-size: 30px;

  @include tablet-down {
    padding-left: 30px; 
    background-size: 18px;
    font-size: 15px;
  }
}

.estimateTop {
  //position: absolute;
  z-index: 8;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;


  @include tablet-down {
    top: 70px;
  }

}

.estimateTopForm {
  composes: estimateTop;

  padding: 60px 0;
  justify-content: flex-start;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.80);
  top: 0;
  min-height: 100%;
  box-sizing: border-box;


  @include tablet-down {
    padding-top: 80px;
  }

  & + .estimateBottom {
    h3 {
      color: #fff;
    }
  }
}
.estimateTopFormDark {
  composes: estimateTopForm;

  background: rgba(0, 0, 0, 0.80);
}

.estimateBottom {
  //position: absolute;
  z-index: 8;
  bottom: 20px;
  width: 100%;


  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @include tablet-down {
    display: none;
  }
  h3 {
    color: #fff;
    font-weight: 400;
    margin: 0;
  }

  ul {
    list-style: none;
    padding: 0px 20px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 580px;

    li {
      transition: 0.15s ease-in;
      &:global(.active) {
        background-color: #A10101;
        box-shadow: inset 2px 0 5px 0 rgba(0,0,0,0.17);
        color: #fff;
        
      }
      &:global(.not-active) {
        background-color: rgba(255, 255, 255, 0.8);

      &:hover {

        box-shadow: 0 1px 2px 0 rgba(0,0,0,0.30);
      }
      }
      margin: 10px;
      cursor: pointer;

      padding: 10px;
      border-radius: 8px;

      
    }
  }
}


.disableBtn {
  background: #9B9B9B;
  border: none;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  font-size: 20px;
  border-radius: 8px;
  padding: 0 20px;
}

.downloadBtn {
  composes: disableBtn;

  position: relative;
  font-size: 18px;
  transition: background-color 0.3s ease-in;
  padding-left: 50px;
  height: 50px;
  background: url(./imgs/upload.svg) no-repeat 20px center;
  background-color: #A10101;
  background-size: 20px;

  &:hover {
    background-color: darken(#A10101, 5);
  }

  input {
    position: absolute;
    border: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
  }
}

.downloadBtn2 {
  composes: downloadBtn;
  padding: 0;
  width: 100px;
  height: 100px;
  background-position: center center;
  margin-left: 10px;
  background-size: 30px;
  
  @include tablet-down {
    width: 60px;
    height: 60px;
  }
}


.escapeFirstLink {
  composes: downloadBtn;
  cursor: pointer;

  background: none;
  // background: url(./imgs/arrow-right.svg) no-repeat center 20px;
  // background-size: 20px;
  // background-position: right center;
  background-color: rgba(0,0,0, 1);
  padding: 0 20px;
  border: 0;
  min-width: 40px;
  height: 40px;
  margin-left: 20px;
  font-size: 16px;

  @include mobile {
    display: none;
  }

}

.escapeFirstLinkSmallMobile {
  composes: escapeFirstLink;
  background: url(./imgs/arrow-right.svg) no-repeat 11px center;
  background-size: 20px;
  background-color: rgba(0,0,0, 1);
  display: none;

  @include mobile {
    display: flex;
  }

}

.escapeFirstLinkSmall {
  display: flex;
  composes: escapeFirstLink;
  background: url(./imgs/arrow-right.svg) no-repeat 11px center;
  background-size: 20px;
  background-color: rgba(0,0,0, 1);

}

.backLink {
  composes: escapeFirstLink;

  display: block;
  background: url(./imgs/arrow-right.svg) no-repeat center 20px;
  background-size: 20px;
  background-position: center center;
  transform: rotate(180deg);
  background-color: rgba(255,255,255, 0.6);
  margin: 0;
}

.photoList {
  display: flex;

}

.photoListItem {
  width: 100px;
  height: 100px;
  background: rgba(255,255,255, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 10px;
  overflow: hidden;
  border-radius: 8px;
  background-image: url(./imgs/circles.svg);
  background-size: 25px;
  background-repeat: no-repeat;
  background-position: center center;


  @include tablet-down {
    width: 60px;
    height: 60px;
  }

  img {
    width: 100%;
    position: absolute;
    
  }

  .deleteBtn {
    cursor: pointer;
    opacity: 0;
    width: 21px;
    height: 21px;
    background: url(./imgs/close.svg) no-repeat center center;
    background-color: #fff;
    background-size: 15px;
    border-radius: 50%;
    position: absolute;
    top: 10px;
    right: 10px;
    transition: 0.25s ease;
    @include tablet-down {
      opacity: 1;
      top: 1px;
      right: 1px;
    }
  }

  &:hover {
    .deleteBtn {
      opacity: 1;
    }
  }
}
