@import '../../styles/_responsive-bg.scss';
@import '../../styles/_utils.scss';
@import '../../styles/components.scss';

.header {
  position: relative;
  min-height: 180px;
  background-attachment: fixed;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;


  .headDarkBg {
    display: block;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.4);
    width: 100%;
    height: 100%;
  }
}

.headerWrapper {
  position: absolute;
  z-index: 1;
  display: flex;
  top: 0;
  left: 0;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  color: #fff;
  box-sizing: border-box;
  padding-top: 40px;
}

