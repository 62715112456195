@import '../../../../styles/_utils.scss';

.body {
  padding-top: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(15, 11, 11);
  //background-image: url('./imgs/bg.jpg');
  background-attachment: fixed;
  background-size: cover;
  background-position: top center;


  min-height: 300px;
  padding-bottom: 60px;
  box-sizing: border-box;


  @include desktop {
    height: auto;
    padding-top: 100px;
    min-height: 450px;
    max-height: 800px;
  }
}