@import '../../../styles/_responsive-bg.scss';
@import '../../../styles/_utils.scss';

.containerFluid {
  width: 100%;
  position: relative;

}

.container {
  composes: container;
  overflow: hidden;

}

.row {
  composes: row;

  flex-direction: column;

  @include desktop {
    flex-direction: row;
  }
}

.head {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100px;
  width: 100%;

  font-size: 36px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.66);
}

.body {
  padding-top: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  padding-bottom: 60px;
  box-sizing: border-box;
  background-image: url("./imgs/reviews-bg.webp");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  @include desktop {
    height: auto;
    padding-top: 100px;
    min-height: 450px;
    max-height: 800px;
  }

}

.reviewLink {
  margin-top: 90px;
  //transform: translateY(90px);
}

.slides {
  composes: container;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: visible;


  @include tablet {
    justify-content: space-between;
  }

  @include desktop {
    align-items: flex-end;
  }
}

.slideItem {
  align-items: center;
  background-color: rgba(255,255,255, 0.88);
  height: 195px;
  width: 195px;
  border-radius: 10px;
  overflow: hidden;
  order: 0;
  transform: translateX(-50%);

  display: none;
  @include tablet {
    display: flex;
  }
  @include desktop {
    transform: translateX(0%);
  }


  animation: slideOut 0.6s ease-in ;

  @keyframes slideOut {

   0% {
    opacity: 0;
 }
    100% {
      opacity: 1;
   }
  }
}

.slideRightItem {
  composes: slideItem;
  order: 3;
  margin-left: 0;
  transform: translateX(50%);

  @include desktop {
    transform: translateX(0%);
  }
}

.slideActiveItem {
  composes: slideItem;
  height: auto;
  width: 100%;
  max-width: 400px;
  order: 2;

  transform: none;
  flex-direction: column;

  display: flex;
  @include tablet {
    width: 400px;
    max-width: none;
  }

  @include desktop {
    flex-direction: row;
    min-height: 285px;
    margin: 0;
    width: 740px;
  }

  .slideDesc { 
    display: flex; 
  }
  .slidePhoto {
    align-items: flex-start;
    background-color: #000;
    height: 285px;
    min-width: 100%;

    &:hover {
      cursor: default;
    }

    @include desktop {
      min-width: 285px;
      height: 285px;
    }

    img {
      filter: none;
    }
  }

  animation: slide 0.3s ease-in ;

  @keyframes slide {
    0% {
      opacity: 0;
      transform: translateY(20px);
   }
   100% {
      opacity: 1;
      transform: translateX(0);
   }
  }

}

.slidePhoto {
  position: relative;
  overflow: hidden;
  min-width: 195px;
  height: 195px;
  background: rgba(0,0,0,1);
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
    img {
      filter: grayscale(0%);

    }
  }

  img {
    filter: grayscale(100%);
    width: 100%;
    height: auto;
    transition: 0.3s ease;

    @include desktop {
      max-width: 285px;
    }
  }

}

.slideDesc {
  padding: 20px 50px 20px 20px;
  color: #4A4A4A;
  line-height: 17px;
  font-size: 12px;
  display: none;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;

  :global(.name) {
    line-height: 1;
    font-size: 20px;
    color: #000;
  }

  :global(.position) {
    margin: 5px 0 0px;
  }

  :global(.link) {
    margin: 5px 0 0px;
    color: rgba(161, 1, 1, 0.6);
    &:hover {
      color: rgba(161, 1, 1, 1);
    }
  }

  p {
    margin: 15px 0 0;
  }
}

.slidesNav {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: -35px;
  left: 50%;
  transform: translateX(-50%);

  > li {
    display: block;
    margin: 0 4px;
    min-width: 15px;
    width: 15px;
    height: 15px;
    min-height: 15px;
    background: #D8D8D8;
    cursor: pointer;
    border-radius: 50%;
    &:global(.is-active) {
      background-color: #A10101;
    }
    > button {
      display: block;
      cursor: pointer;
      opacity: 0;
      
      border-width: 0;
      width: 100%;
      height: 100%;

      &:global(.is-active) {
        background-color: #A10101;
      }
    }
  }
}


.slidesNavArrow {
  position: absolute;
  right: 15px;
  bottom: -45px;
  height: 35px;
  width: 35px;
  padding: 0;
  background: none;
  box-shadow: none;
  border: 0;
  cursor: pointer;
  transition: 0.15s ease;

  &:hover {
    opacity: 1;
  }
  &:hover, &:focus {
    outline: none;
  }

  img {
    width: 100%;
  }

  @include tablet {
    opacity: 0.6;
    top: 50%;
    right: -86px;
    transform:  translateY(-50%);
    height: 195px;
    width: 195px;

    img {
      width: 76px;
    }
  }
  @include desktop {
    display: none;
    opacity: 0.6;
    height: 76px;
    width: 76px;
    right: -86px;
    transform:  translateY(-50%);

    img {
      width: 100%;
    }
  }
}

.slidesNavArrowLeft {
  composes: slidesNavArrow;
  position: absolute;
  right: auto;
  left: 15px;
  transform: rotate(180deg);

  @include tablet {
    right: auto;
    left: -86px;
    transform: rotate(180deg) translateY(50%);
  }
  @include desktop {
    left: -86px;
    transform: rotate(180deg) translateY(50%);
  }
}
