@mixin responsive-background-image($size, $image, $format) {
  background-image: url($image + "." + $format);
  /* 2x */
  @media
  only screen and (-webkit-min-device-pixel-ratio: 1.25),
  only screen and ( min--moz-device-pixel-ratio: 1.25),
  only screen and ( -o-min-device-pixel-ratio: 1.25/1),
  only screen and ( min-device-pixel-ratio: 1.25),
  only screen and ( min-resolution: 200dpi),
  only screen and ( min-resolution: 1.25dppx)
  {
    background-image: url($image + "@2x." + $format);
    background-size: $size;
  }
  /* 3x */
  @media only screen and (-o-min-device-pixel-ratio: 9/4),
  only screen and (-webkit-min-device-pixel-ratio: 2.25),
  only screen and (min-device-pixel-ratio: 2.25),
  only screen and (min-resolution: 2.25dppx) {
    background-image: url($image + "@3x." + $format);
    background-size: $size;
  }
}