.btn {
  border: 0;
  border-radius: 8px;
  height: 55px;
  padding: 0 17px;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #4A4A4A;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50);
  color: #fff;
  width: 260px;
  margin: 10px 0px;
  cursor: pointer;
  transition: 0.1s;
  box-sizing: border-box;

  &:hover {
    text-decoration: none;
  }

  @include tablet {
    margin: 0 10px;
  }
}
