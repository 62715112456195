@import '../../../../styles/_responsive-bg.scss';
@import '../../../../styles/_utils.scss';
@import '../../../../styles/components.scss';


.formSend {
  max-width: 360px;
  width: 100%;
}

.formGroup {
  width: 100%;
  margin: 14px 0;
  color: #fff;
  font-size: 12px;
  line-height: 18px;
  

  @include tablet-down {
    padding: 0 20px;
    box-sizing: border-box;
  }

  a {
    color: #ff0101;
  }
}

.formBtnGroup {
  composes: formGroup;
  display: flex;
  justify-content: space-between;
}

.textField {
  width: 100%;
  resize: none;
  box-sizing: border-box;
  border: none;
  padding: 10px 15px;
  font-size: 16px;
  border-radius: 8px;
  height: 48px;
}

.checkbox {
  margin-right: 10px;
}

.submitBtn {
  composes: btn;
  background-image: none;
  background-color: #A10101;
  justify-content: flex-start;
  background-image: none;
  width: auto;
  margin: 0;
  height: 40px;
  padding: 0 20px;
  font-size: 18px;

  &:disabled {
    background: #9B9B9B;
    cursor: default;
    font-size: 18px;
  }
}
.recaptcha {
  font-size: 8px;
  color: #ccc;

  > a {
    color: #ccc;
    text-decoration: underline;
  }
}