@import '../../../styles/_responsive-bg.scss';
@import '../../../styles/_utils.scss';

.containerFluid {
  width: 100%;
  position: relative;
  // margin: 40px 0;
  background-color: #e4d0433d;
  margin: 0;

  &:after {
    content: '';
    position: absolute;
    width: 90%;
    height: 90%;
    transform: translate(-50%, -50%);
    border: 10px solid #A10101;
    top: 50%;
    left: 50%;
  }

}

.container {
  composes: container;
  padding: 0 30px;

  
  @include tablet {
    padding: 0 60px;
  }
}

.head {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  font-weight: 400;

  font-size: 22px;
  margin: 0;
  padding: 60px 22px 20px;
  color: #000000;
  box-sizing: border-box;
  text-align: center;

  @include tablet {
    font-size: 36px;
    padding: 60px 60px 20px;
  }
}

.body {
  font-size: 18px;
  color: #4A4A4A;
  text-align: center;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 330px;
  box-sizing: border-box;
  // background-color: rgba(255,255,255,0.5);
  line-height: 1.2;
  text-shadow: 0px 1px 1px rgba(0,0,0,0.4);
  position: relative;
  z-index: 77;

  br {
    display: none;
  }

  @include tablet {
    font-size: 24px;
    br {
      display: inline;
    }
  }

  strong {
    font-weight: 400;
    color: #A10101;
  }
}


// @include jpg-or-webp-background-image(
//   cover,
//   "./imgs/Bitmap",
//   ".containerFluid"
// );


.ctaBtns {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 34px;
  width: 100%;
  font-size: 15px;
  flex-direction: column;

  @include tablet {
    flex-direction: row;
    font-size: 18px;
  }
}

.btn {
  border: 0;
  border-radius: 8px;
  height: 55px;
  padding: 0 17px;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #4A4A4A;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50);
  color: #fff;
  min-width: 260px;
  margin: 10px 30px;
  cursor: pointer;
  transition: 0.1s;

  @include tablet {
    margin: 0 10px;
    
  }
}

.btnLeft {
  composes: btn;
  background-image: url(../Head/imgs/whatsapp.svg);
  background-repeat: no-repeat;
  background-position: 20px center;
  background-size: 27px;
  padding-left: 62px;

  &:hover {
    background-color: #25D366;
  }
}

.btnRight {
  composes: btn;
  background-image: none;
  background-color: #A10101;
  justify-content: flex-start;

  span {
    display: block;

    &:nth-child(2) {
      margin-left: 21px;
    }
  }
}
