@import '../../../styles/_utils.scss';

.container {
  composes: container;
  overflow: hidden;

}

.row {
  composes: row;

  flex-direction: column;

  @include desktop {
    flex-direction: row;
  }
}

.col {
  display: flex;
  align-items: center;
  overflow: hidden;
  order: 1;
  width: 100%;

  img, picture {
    width: 100%;
  }

  @include tablet {
    order: 0;
    width: 768px;
    height: 250px;

    img, picture {
      width: 768px;
    }
  }

  @include desktop {
    order: 0;
    height: 400px;
    width: 640px;
    min-width: 640px;

    img, picture {
      width: 640px;
    }
  }
} 

.colText {
  padding: 20px;
  overflow: hidden;
  order: 2;
  @include tablet {
    padding: 30px;
  }
  @include desktop {
    order: 0;
    min-height: 400px;
  }

  h3 {
    color: #A10101;
    font-size: 24px;
    text-align: left;
    margin: 13px 0 35px;
    font-weight: 400;

    @include tablet {

      font-size: 28px;
    }
  }

  p {
    font-size: 16px;
    text-align: justify;
    line-height: 1.7;
    color: #4A4A4A;
    margin: 0;
    padding: 0;

    @include tablet {

      font-size: 18px;
    }
  }
}